<template>
  <v-app>
    <top-bar @update="menu = !menu"></top-bar>

    <v-navigation-drawer
      v-model="menu"
      floating
      :location="$vuetify.display.mobile ? 'bottom' : undefined"
      temporary
      :width="325"
    >
      <v-list density="compact" nav>
        <v-list-item
          active-class="active-menu elevation-6"
          class="mt-5 title-menu"
          value="mes points"
          @click="pushView('points')"
        >
          <template v-slot:title>
            <span class="text-subtitle-1"> {{ $t("menu.use-points") }}</span>
          </template>
          <template v-slot:prepend>
            <v-icon color="#feb100" :icon="mdiStarOutline" size="40"></v-icon>
          </template>
        </v-list-item>
        <v-list-item
          active-class="active-menu elevation-6"
          class="mt-3 mb-3 title-menu"
          value="mes commandes one stryker"
          @click="pushView('stryker-one-commands')"
        >
          <template v-slot:title>
            <span class="text-subtitle-1">
              {{ $t("menu.my-stryker-one-commands") }}</span
            >
          </template>
          <template v-slot:prepend>
            <v-icon
              color="#feb100"
              :icon="mdiPackageVariant"
              size="40"
            ></v-icon>
          </template>
        </v-list-item>
        <v-list-item
          active-class="active-menu elevation-6"
          class="mt-3 mb-3 title-menu"
          value="mes commandes stryker"
          @click="pushView('stryker-commands')"
        >
          <template v-slot:title>
            <span class="text-subtitle-1">
              {{ $t("menu.my-stryker-commands") }}</span
            >
          </template>
          <template v-slot:prepend>
            <v-icon
              color="#feb100"
              :icon="mdiPackageVariant"
              size="40"
            ></v-icon>
          </template>
        </v-list-item>
        <v-list-item
          active-class="active-menu elevation-6"
          class="mt-3 mb-3 title-menu"
          value="Mon contrat"
          @click="pushView('contrat')"
        >
          <template v-slot:title>
            <span class="text-subtitle-1"> {{ $t("menu.contrat") }}</span>
          </template>
          <template v-slot:prepend>
            <v-icon
              color="#feb100"
              :icon="mdiAccountBoxOutline"
              size="40"
            ></v-icon>
          </template>
        </v-list-item>
        <v-list-item
          active-class="active-menu elevation-6"
          class="mt-3 mb-3 title-menu"
          value="Service One Stryker"
          @click="pushView('service-one-stryker')"
        >
          <template v-slot:title>
            <span class="text-subtitle-1">
              {{ $t("menu.one-stryker-service") }}</span
            >
          </template>
          <template v-slot:prepend>
            <v-icon color="#feb100" :icon="mdiCogOutline" size="40"></v-icon>
          </template>
        </v-list-item>
        <v-list-item
          active-class="active-menu elevation-6"
          class="mt-3 mb-3 title-menu"
          value="contact"
          @click="pushView('contact')"
        >
          <template v-slot:title>
            <span class="text-subtitle-1"> {{ $t("menu.contact") }}</span>
          </template>
          <template v-slot:prepend>
            <v-icon color="#feb100" :icon="mdiForumOutline" size="40"></v-icon>
          </template>
        </v-list-item>
        <v-list-item
          v-if="$store.state.users.user?.userRole.toUpperCase() === 'ADMIN'"
          active-class="active-menu elevation-6"
          class="mt-3 mb-3 title-menu"
          value="admin"
          @click="pushView('admin')"
        >
          <template v-slot:title>
            <span class="text-subtitle-1">
              {{ $t("menu.administration") }}</span
            >
          </template>
          <template v-slot:prepend>
            <v-icon color="#feb100" :icon="mdiDatabaseCog" size="40"></v-icon>
          </template>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <snackbar-component></snackbar-component>
      <router-view />
      <v-overlay :model-value="overlay" class="align-center justify-center">
        <v-progress-circular
          color="#ffb300"
          size="64"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </v-main>
    <footer-component></footer-component>
  </v-app>
</template>

<script>
import FooterComponent from "./components/FooterComponent.vue";
import SnackbarComponent from "./components/SnackbarComponent.vue";
import TopBar from "./components/TopBar.vue";
import {
  mdiStarOutline,
  mdiPackageVariant,
  mdiAccountBoxOutline,
  mdiCogOutline,
  mdiForumOutline,
  mdiDatabaseCog
} from "@mdi/js";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    FooterComponent,
    TopBar,
    SnackbarComponent
  },
  data() {
    return {
      menu: null,
      mdiStarOutline,
      mdiPackageVariant,
      mdiAccountBoxOutline,
      mdiCogOutline,
      mdiForumOutline,
      mdiDatabaseCog
    };
  },
  computed: {
    ...mapState({
      overlay: (state) => state.overlay,
      customer: (state) => state.users.selectedCustomer.id
    })
  },
  methods: {
    pushView(view) {
      if (this.$route.name != view) {
        if (view === "stryker-commands" && this.customer !== -1) {
          this.$store.commit("strykerCommands/setItems", []);
          this.$store.dispatch(
            "strykerCommands/fetchCustommerCommands",
            this.customer
          );
          const data = {
            id: this.customer,
            name: ""
          };
          this.$store.dispatch(
            "statsCustomer/fetChSpecificStatsCustomer",
            data
          );
          this.$router.push({ name: view });
        } else if (view === "points") {
          this.$store.dispatch("products/fetchAll").then((response) => {
            this.$store.dispatch("cart/fetchCartByCustomer", this.customer);
            this.$store.commit(
              "paginations/setItems",
              JSON.parse(response.headers.get("X-Pagination"))
            );
            this.$router.push({ name: view });
          });
        } else if (view === "stryker-one-commands") {
          this.$store.commit("ordersOneStryker/setItems", []);
          this.$store.dispatch(
            "ordersOneStryker/fetchCustommerCommands",
            this.customer
          );
          const data = {
            id: this.customer,
            name: ""
          };
          this.$store.dispatch(
            "statsCustomer/fetChSpecificStatsCustomer",
            data
          );
          this.$router.push({ name: view });
        } else {
          this.$router.push({ name: view });
        }
      }
    }
  }
};
</script>

<style>
.active-menu {
  border-left: 4px solid #feb100;
  border-top-left-radius: 6px; /* Round the top-left corner */
  border-bottom-left-radius: 6px; /* Round the bottom-left corner */
}

.title-menu {
  color: black;
  font-size: 1rem !important;
}

.align-icon-right {
  text-align: right;
}
</style>
