import { createStore } from "vuex";
import auth from "./modules/auth";
import users from "./modules/users";
import clients from "./modules/clients";
import paginations from "./modules/paginations";
import products from "./modules/products";
import strykerCommands from "./modules/strykerCommands";
import buyingGroups from "./modules/buyingGroups";
import roles from "./modules/roles";
import franchises from "./modules/franchises";
import franchiseGroups from "./modules/franchiseGroups";
import subFranchises from "./modules/subFranchises";
import gimBrands from "./modules/gimBrands";
import gimItemTypes from "./modules/gimItemTypes";
import gimProductLines from "./modules/gimProductLines";
import gimSubBrands from "./modules/gimSubBrands";
import targetProductGroups from "./modules/targetProductGroups";
import sapgs from "./modules/sapgs";
import statsCustomer from "./modules/statsCustomer";
import gimCustoms from "./modules/gimCustoms";
import sapgCustoms from "./modules/sapgCustoms";
import cart from "./modules/cart";
import addresses from "./modules/addresses";
import ordersOneStryker from "./modules/ordersOneStryker";

import upload from "../api/upload";

export default createStore({
  state: {
    snackbar: {
      model: false,
      text: "",
      color: ""
    },
    overlay: false,
    loading: false,
    loadingTables: false
  },
  modules: {
    auth,
    users,
    clients,
    paginations,
    products,
    strykerCommands,
    buyingGroups,
    roles,
    franchises,
    franchiseGroups,
    statsCustomer,
    subFranchises,
    gimBrands,
    gimItemTypes,
    gimProductLines,
    gimSubBrands,
    targetProductGroups,
    sapgs,
    sapgCustoms,
    gimCustoms,
    cart,
    addresses,
    ordersOneStryker
  },
  getters: {},
  actions: {
    fetchUserAdmin({ dispatch }) {
      return Promise.all([dispatch("roles/fetchAll")]);
    },
    fetchProductAdmin({ dispatch }) {
      return Promise.all([
        dispatch("products/fetchAll"),
        dispatch("franchises/fetchAll"),
        dispatch("franchiseGroups/fetchAll"),
        dispatch("subFranchises/fetchAll"),
        dispatch("gimBrands/fetchAll"),
        dispatch("gimItemTypes/fetchAll"),
        dispatch("gimProductLines/fetchAll"),
        dispatch("targetProductGroups/fetchAll"),
        dispatch("sapgs/fetchAll")
      ]);
    },
    importFile({ commit }, formData) {
      return new Promise((resolve, reject) => {
        upload
          .import(formData)
          .then((response) => {
            commit("setLoading", false);
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    showSnackbar({ commit }, payload) {
      commit("showSnackbar", payload);
    },
    hideSnackbar({ commit }) {
      commit("hideSnackbar");
    }
  },
  mutations: {
    showSnackbar(state, { message, color }) {
      state.snackbar.model = true;
      state.snackbar.text = message;
      state.snackbar.color = color;
    },
    hideSnackbar(state) {
      state.snackbar.model = false;
      state.snackbar.text = "";
      state.snackbar.color = "";
    },
    setLoading(state, value) {
      state.loading = value;
    },
    setLoadingTables(state, value) {
      state.loadingTables = value;
    },
    setOverlay(state, value) {
      state.overlay = value;
    },
    setSnackbar(state, value) {
      state.snackbar = value;
    }
  }
});
