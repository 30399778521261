<template>
  <v-container class="container-view" fluid>
    <v-row class="d-flex align-center justify-center flex-wrap text-center">
      <div class="text-h5 font-weight-medium">{{ this.customer.name }}</div>
    </v-row>
    <v-row class="d-flex align-center justify-center flex-wrap text-center">
      <div class="text-h6 font-weight-medium">
        <span class="text-h6 font-weight-black">
          Status
          {{
            this.customer.status.number == "" ||
            this.customer.status.number < 0 ||
            this.customer.status.number == null
              ? "0"
              : this.customer.status.number
          }}
          -
        </span>
        <span class="color-point">
          {{
            this.stats.totalPoints == 0 || this.stats.totalPoints < 0
              ? 0
              : isDivisibleBy(this.stats.totalPoints, 1000000)
              ? (this.stats.totalPoints / 1000000).toFixed(2) + "M"
              : isDivisibleBy(this.stats.totalPoints, 1000)
              ? (this.stats.totalPoints / 1000).toFixed(2) + "K"
              : this.stats.totalPoints
          }}
          points restants
        </span>
      </div>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-card height="52vh" elevation="6">
          <v-card-title class="title-background">
            {{ $t("my-last-one-stryker-commands") }}
          </v-card-title>
          <v-card-text>
            <v-data-table
              :items="commandes"
              :headers="headers"
              show-expand
              item-value="nom"
              :no-data-text="$t('no-data')"
            >
              <template v-slot:expanded-row="{ columns, item }">
                <tr>
                  <td :colspan="columns.length">
                    More info about {{ item.nom }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" class="pl-0">
        <div id="chart">
          <apexchart
            class="apex-chart"
            type="donut"
            width="500"
            :options="chartOptions"
            :series="stats.totals"
          ></apexchart>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "StrykerOneCommands",
  data() {
    return {
      headers: [
        {
          title: this.$t("admin.customer-name"),
          align: "start",
          sortable: false,
          key: "customer.name"
        },
        { title: this.$t("order-number"), key: "orderNumber" },
        { title: this.$t("total"), key: "totalAmount" }
      ],
      chartOptions: {
        chart: {
          width: 380,
          type: "donut"
        },
        dataLabels: {
          enabled: true
        },
        labels: ["NON ALLOCATED"],
        colors: [
          "#FFB300",
          "#B66A00",
          "#80BDB8",
          "#0092E2",
          "#22605B",
          "#397D7D",
          "#005C87",
          "#DDD8D9",
          "#858482",
          "#3761C2",
          "#532571",
          "#B6743A"
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                show: false
              }
            }
          }
        ],
        legend: {
          position: "right",
          offsetY: 0
        }
      }
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.users.selectedCustomer,
      stats: (state) => state.statsCustomer.statsCustomer
    }),
    pageCount() {
      return Math.ceil(this.commandes.length / this.itemsPerPage);
    },
    commandes: {
      get() {
        return this.$store.state.ordersOneStryker.items;
      }
    }
  },
  watch: {
    stats: {
      handler(newStats) {
        this.chartOptions.labels = newStats.names;
      },
      immediate: true
    }
  },
  methods: {
    isDivisibleBy(value, divisor) {
      return Math.floor(value / divisor) > 0;
    }
  }
};
</script>

<style scoped>
.apex-chart {
  padding-top: 50px;
}
</style>
