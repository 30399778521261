import axios from "axios";

export default {
  get(paginationString) {
    if (paginationString) {
      return axios.get(`/api/targetProductGroups${paginationString.text}`);
    } else {
      return axios.get("/api/targetProductGroups");
    }
  },
  store(targetProductGroup) {
    return axios.post("/api/targetProductGroups", targetProductGroup);
  },
  delete(id) {
    return axios.delete(`/api/targetProductGroups/${id}`);
  },
  update(id, targetProductGroup) {
    return axios.put(`/api/targetProductGroups/${id}`, targetProductGroup);
  }
};
