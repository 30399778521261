import axios from "axios";

export default {
  get(paginationString) {
    if (paginationString) {
      return axios.get(`/api/gimProductLines${paginationString.text}`);
    } else {
      return axios.get("/api/gimProductLines");
    }
  },
  store(gimProductLine) {
    return axios.post("/api/gimProductLines", gimProductLine);
  },
  delete(id) {
    return axios.delete(`/api/gimProductLines/${id}`);
  },
  update(id, gimProductLine) {
    return axios.put(`/api/gimProductLines/${id}`, gimProductLine);
  }
};
