<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card :prepend-icon="mdiAccount" :title="formTitle">
      <v-form ref="form" @submit.prevent>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="4" sm="6">
              <v-text-field
                v-if="editedItem.customerSoldTo"
                :label="$t('admin.customer-sold-to')"
                disabled
                v-model="editedItem.customerSoldTo.number"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                v-if="editedItem.customerReported"
                :label="$t('admin.customer-reported') + ' *'"
                v-model="editedItem.customerReported.number"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.reference')"
                v-model="editedItem.reference"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.sales-amount')"
                v-model="editedItem.salesAmount"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.sales-recognition-date')"
                v-model="editedItem.salesRecognitionDate"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('city')"
                v-model="editedItem.city"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.buying-group')"
                v-model="editedItem.buyingGroupName"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.currency')"
                v-model="editedItem.currency"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-textarea
                :label="$t('description')"
                v-model="editedItem.description"
                disabled
              ></v-textarea>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.franchise')"
                v-model="editedItem.franchise"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.franchise-group')"
                v-model="editedItem.franchiseGroup"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.gim-brand')"
                v-model="editedItem.gimBrand"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.gim-item-type')"
                v-model="editedItem.gimItemType"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.gim-sub-brand')"
                v-model="editedItem.gimSubBrand"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.invoice-number')"
                v-model="editedItem.invoiceNumber"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('order-number')"
                v-model="editedItem.orderNumber"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.gim-item-type')"
                v-model="editedItem.gimItemType"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.sapg')"
                v-model="editedItem.sapg"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.sub-franchise')"
                v-model="editedItem.subFranchise"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.gim-uom')"
                v-model="editedItem.uom"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.updated-at')"
                v-model="editedItem.updatedAt"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <small class="text-caption text-medium-emphasis">
            {{ $t("require-fields") }}
          </small>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            :text="$t('close')"
            variant="plain"
            @click="$emit('close')"
          ></v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiPencil, mdiAccount } from "@mdi/js";
import { mapState } from "vuex";

export default {
  name: "AddEditOrderOneStryker",
  props: ["modelDialog"],
  data() {
    return {
      mdiPencil,
      mdiAccount
    };
  },
  computed: {
    dialog: {
      get() {
        return this.modelDialog;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    ...mapState({
      editedId: (state) => state.ordersOneStryker.editedId,
      editedItem: (state) => state.ordersOneStryker.editedItem
    }),
    formTitle() {
      return this.editedId === -1
        ? this.$t("admin.new-command-stryker")
        : this.$t("admin.edit-command-stryker");
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
