<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card :prepend-icon="mdiPackageVariantClosed" :title="formTitle">
      <v-form ref="form" @submit.prevent>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('reference') + ' *'"
                v-model="editedItem.reference"
                :rules="referenceRules"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.lppr')"
                v-model="editedItem.lppr"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-textarea
                :label="$t('description') + ' *'"
                v-model="editedItem.description"
                :rules="descriptionRules"
                required
              ></v-textarea>
            </v-col>

            <v-col cols="12" sm="6">
              <v-textarea
                :label="$t('description-local') + ' *'"
                v-model="editedItem.descriptionLocal"
                required
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.price') + ' *'"
                v-model="editedItem.price"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('vat')"
                v-model="editedItem.tva"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.currency') + ' *'"
                v-model="editedItem.currency"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-autocomplete
                :label="$t('admin.franchise')"
                v-model="editedItem.franchise"
                :items="franchises"
                @update:search="debouncedUpdateFranchises"
                item-title="name"
                item-value="id"
              ></v-autocomplete>
              <!-- <v-text-field
                :label="$t('admin.franchise')"
                v-model="editedItem.fK_Franchise"
              ></v-text-field> -->
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-autocomplete
                :label="$t('admin.franchise-group')"
                v-model="editedItem.franchiseGroup"
                :items="franchiseGroups"
                @update:search="debouncedUpdateFranchiseGroups"
                item-title="name"
                item-value="id"
              ></v-autocomplete>
              <!-- <v-text-field
                :label="$t('admin.franchise-group')"
                v-model="editedItem.fK_FranchiseGroup"
              ></v-text-field> -->
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-autocomplete
                :label="$t('admin.sub-franchise')"
                v-model="editedItem.subFranchise"
                :items="franchiseGroups"
                @update:search="debouncedUpdateSubFranchises"
                item-title="name"
                item-value="id"
              ></v-autocomplete>
              <!-- <v-text-field
                :label="$t('admin.sub-franchise')"
                v-model="editedItem.fK_SubFranchise"
              ></v-text-field> -->
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-autocomplete
                :label="$t('admin.gim-brand')"
                v-model="editedItem.gimBrand"
                :items="gimBrands"
                @update:search="debouncedUpdateGimBrands"
                item-title="name"
                item-value="id"
              ></v-autocomplete>
              <!-- <v-text-field
                :label="$t('admin.gim-brand')"
                v-model="editedItem.fK_GimBrand"
              ></v-text-field> -->
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-autocomplete
                :label="$t('admin.gim-item-type')"
                v-model="editedItem.gimItemType"
                :items="gimItemTypes"
                @update:search="debouncedUpdateGimItemTypes"
                item-title="name"
                item-value="id"
              ></v-autocomplete>
              <!-- <v-text-field
                :label="$t('admin.gim-item-type')"
                v-model="editedItem.fK_GimItemType"
              ></v-text-field> -->
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-autocomplete
                :label="$t('admin.gim-product-line')"
                v-model="editedItem.gimProductLine"
                :items="gimProductLines"
                @update:search="debouncedUpdateGimProductLines"
                item-title="name"
                item-value="id"
              ></v-autocomplete>
              <!-- <v-text-field
                :label="$t('admin.gim-item-type')"
                v-model="editedItem.fK_GimProductLine"
              ></v-text-field> -->
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-autocomplete
                :label="$t('admin.gim-sub-brand')"
                v-model="editedItem.gimSubBrand"
                :items="gimSubBrands"
                @update:search="debouncedUpdateGimSubBrands"
                item-title="name"
                item-value="id"
              ></v-autocomplete>
              <!-- <v-text-field
                :label="$t('admin.gim-sub-brand')"
                v-model="editedItem.fK_GimSubBrand"
              ></v-text-field> -->
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-autocomplete
                :label="$t('admin.sapg')"
                v-model="editedItem.sapg"
                :items="sapgs"
                @update:search="debouncedUpdateSapgs"
                item-title="name"
                item-value="id"
              ></v-autocomplete>
              <!-- <v-text-field
                :label="$t('admin.sapg')"
                v-model="editedItem.fK_SAPG"
              ></v-text-field> -->
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-autocomplete
                :label="$t('admin.target-product-group')"
                v-model="editedItem.targetProductGroup"
                :items="targetProductGroups"
                @update:search="debouncedUpdateTargetProductGroups"
                item-title="name"
                item-value="id"
              ></v-autocomplete>
              <!-- <v-text-field
                :label="$t('admin.target-product-group')"
                v-model="editedItem.fK_TargetProductGroup"
              ></v-text-field> -->
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.gim-id') + ' *'"
                v-model="editedItem.gimID"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.gim-pack-content') + ' *'"
                v-model="editedItem.gimPackContent"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.gim-sterility-flag') + ' *'"
                v-model="editedItem.gimSterilityFlag"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.gim-uom') + ' *'"
                v-model="editedItem.gimUOM"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.gtin-code') + ' *'"
                v-model="editedItem.gtinCode"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.primary-uom') + ' *'"
                v-model="editedItem.primaryUOM"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('admin.updated-at')"
                disabled
                :model-value="
                  editedItem.updatedAt != null
                    ? formatedDate(editedItem.updatedAt)
                    : ''
                "
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            :text="$t('close')"
            variant="plain"
            @click="$emit('close')"
          ></v-btn>

          <v-btn
            color="#ffb500"
            type="submit"
            :text="$t('save')"
            variant="tonal"
            @click="save"
          ></v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import paginations from "@/mixins/paginations";
import { mdiPackageVariantClosed, mdiPencil } from "@mdi/js";
import _ from "lodash"; // Importer lodash

export default {
  name: "AddEditProduct",
  props: ["modelDialog"],
  mixins: [paginations],
  data() {
    return {
      mdiPackageVariantClosed,
      mdiPencil,
      referenceRules: [
        (value) => {
          if (value) return true;
          return "Reference is mandatory";
        }
      ],
      descriptionRules: [
        (value) => {
          if (value) return true;
          return "Reference is mandatory";
        }
      ]
    };
  },
  created() {
    // Déclarer une méthode débouncée avec un paramètre supplémentaire
    this.debouncedUpdateFranchises = _.debounce(
      (item) => this.updateFields(item, "franchises"),
      300
    );
    this.debouncedUpdateFranchiseGroups = _.debounce(
      (item) => this.updateFields(item, "franchiseGroups"),
      300
    );
    this.debouncedUpdateSubFranchises = _.debounce(
      (item) => this.updateFields(item, "subFranchises"),
      300
    );
    this.debouncedUpdateGimBrands = _.debounce(
      (item) => this.updateFields(item, "gimBrands"),
      300
    );
    this.debouncedUpdateGimItemTypes = _.debounce(
      (item) => this.updateFields(item, "gimItemTypes"),
      300
    );
    this.debouncedUpdateGimProductLines = _.debounce(
      (item) => this.updateFields(item, "gimProductLines"),
      300
    );
    this.debouncedUpdateGimSubBrands = _.debounce(
      (item) => this.updateFields(item, "gimSubBrands"),
      300
    );
    this.debouncedUpdateSapgs = _.debounce(
      (item) => this.updateFields(item, "sapgs"),
      300
    );
    this.debouncedUpdateTargetProductGroups = _.debounce(
      (item) => this.updateFields(item, "targetProductGroups"),
      300
    );
  },
  computed: {
    dialog: {
      get() {
        return this.modelDialog;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    ...mapState({
      editedId: (state) => state.products.editedId,
      franchises: (state) => state.franchises.items,
      franchiseGroups: (state) => state.franchiseGroups.items,
      gimBrands: (state) => state.gimBrands.items,
      gimItemTypes: (state) => state.gimItemTypes.items,
      gimProductLines: (state) => state.gimProductLines.items,
      gimSubBrands: (state) => state.gimSubBrands.items,
      sapgs: (state) => state.sapgs.items,
      targetProductGroups: (state) => state.targetProductGroups.items
    }),
    editedItem: {
      get() {
        return this.$store.state.products.editedItem;
      },
      set(value) {
        this.$store.commit("products/setEditedItem", value);
      }
    },
    formTitle() {
      return this.editedId === -1
        ? this.$t("admin.new-product")
        : this.$t("admin.edit-product");
    }
  },
  methods: {
    updateFields(item, search) {
      this.searchBack(item, 500, search, false);
    },
    formatedDate(inputDate) {
      // Convertir en objet Date
      const date = new Date(inputDate);

      // Extraire le jour, le mois et l'année
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Les mois commencent à 0
      const year = date.getFullYear();
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    },
    close() {
      this.$emit("close");
    },
    save() {
      const valid = this.$refs.form.validate();
      if (valid) {
        if (this.editedId === -1) {
          this.$store
            .dispatch("products/saveEditedItem")
            .then(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.success.product-create"),
                color: "success"
              });
              this.close();
            })
            .catch(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.error.product-create"),
                color: "error"
              });
              this.close();
            });
        } else {
          this.$store
            .dispatch("products/updateEditedItem")
            .then(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.success.product-update"),
                color: "success"
              });
              this.close();
            })
            .catch(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.error.product-update"),
                color: "error"
              });
              this.close();
            });
        }
      }
    }
  }
};
</script>
