export default {
  name: "paginate",
  data() {
    return {
      //
    };
  },
  methods: {
    sortBy(item, store) {
      this.$store.commit("setOverlay", true);
      if (item && item[0]) {
        var columnSorted = item[0].key;
        var columnOrder = item[0].order;
        this.$store.commit("paginations/setCurrentFilter", item[0].key);
        this.$store.commit("paginations/setCurrentOrderFilter", item[0].order);
        var paginationParams = "?orderBy=" + columnSorted + " " + columnOrder;
        if (this.search != "") {
          paginationParams += "&search=" + this.search;
        }
        const data = {
          text: paginationParams
        };
        this.$store.dispatch(`${store}/fetchAll`, data).then((response) => {
          this.$store.commit("setOverlay", false);
          this.$store.commit(
            "paginations/setItems",
            JSON.parse(response.headers.get("X-Pagination"))
          );
        });
      } else {
        var paginationParamsElse = "";
        if (this.search != "") {
          paginationParamsElse += "?search=" + this.search;
        }
        const data = {
          text: paginationParamsElse
        };
        this.$store.commit("paginations/setCurrentFilter", "");
        this.$store.commit("paginations/setCurrentOrderFilter", "");
        if (paginationParamsElse != "") {
          this.$store.dispatch(`${store}/fetchAll`, data).then((response) => {
            this.$store.commit("setOverlay", false);
            this.$store.commit(
              "paginations/setItems",
              JSON.parse(response.headers.get("X-Pagination"))
            );
          });
        } else {
          this.$store.dispatch(`${store}/fetchAll`).then((response) => {
            this.$store.commit("setOverlay", false);
            this.$store.commit(
              "paginations/setItems",
              JSON.parse(response.headers.get("X-Pagination"))
            );
          });
        }
      }
    },
    searchBack(value, time = 500, store, updatePagination = true) {
      this.$store.commit("setOverlay", true);
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.search = value;
        if (this.search.length >= 2) {
          // &search=Surveyor12
          var paginationParams = "?search=" + this.search + " ";
          const data = {
            text: paginationParams
          };
          this.$store.dispatch(`${store}/fetchAll`, data).then((response) => {
            this.$store.commit("setOverlay", false);
            if (updatePagination) {
              this.$store.commit(
                "paginations/setItems",
                JSON.parse(response.headers.get("X-Pagination"))
              );
            }
          });
        } else if (this.search == "") {
          this.$store.dispatch(`${store}/fetchAll`).then((response) => {
            this.$store.commit("setOverlay", false);
            if (updatePagination) {
              this.$store.commit(
                "paginations/setItems",
                JSON.parse(response.headers.get("X-Pagination"))
              );
            }
          });
        }
      }, time);
    },
    searchBackSecondStore(value, time = 500, store, updatePagination = true) {
      this.$store.commit("setOverlay", true);
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.search = value;
        if (this.search.length >= 2) {
          // &search=Surveyor12
          var paginationParams = "?search=" + this.search + " ";
          const data = {
            text: paginationParams
          };
          this.$store.dispatch(`${store}/fetchItems`, data).then((response) => {
            this.$store.commit("setOverlay", false);
            if (updatePagination) {
              this.$store.commit(
                "paginations/setItems",
                JSON.parse(response.headers.get("X-Pagination"))
              );
            }
          });
        } else if (this.search == "") {
          this.$store.dispatch(`${store}/fetchItems`).then((response) => {
            this.$store.commit("setOverlay", false);
            if (updatePagination) {
              this.$store.commit(
                "paginations/setItems",
                JSON.parse(response.headers.get("X-Pagination"))
              );
            }
          });
        }
      }, time);
    }
  }
};
