import customers from "../../api/customers";

export default {
  namespaced: true,
  state: {
    items: [],
    secondItemsList: [],
    editedId: -1,
    users: [],
    editedItem: {
      buyingGroup: null,
      email: null,
      fK_BuyingGroup: null,
      fK_Status: null,
      name: "",
      number: "",
      phone: "",
      updatedAt: null,
      updatedBy: null
    },
    defaultItem: {
      buyingGroup: null,
      email: null,
      fK_BuyingGroup: null,
      fK_Status: null,
      name: "",
      number: "",
      phone: "",
      updatedAt: null,
      updatedBy: null
    }
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setEditedItem(state, item) {
      const editedItem = {
        buyingGroup: item.buyingGroup,
        email: item.email,
        fK_BuyingGroup: item.fK_BuyingGroup,
        fK_Status: item.fK_Status,
        name: item.name,
        number: item.number,
        phone: item.phone,
        updatedAt: item.updatedAt,
        updatedBy: item.updatedBy
      };
      state.editedItem = editedItem;
    },
    setEditedId(state, id) {
      state.editedId = id;
    },
    setUsers(state, users) {
      state.users = users;
    },
    setSecondItemsList(state, items) {
      state.secondItemsList = items;
    }
  },
  actions: {
    fetchAll({ commit }, data) {
      return new Promise((resolve, reject) => {
        customers
          .get(data)
          .then((response) => {
            commit("setItems", response.data);
            commit("setSecondItemsList", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    // This function is doing exactly the same as fetchAll, but we need it to be able to link customers between each other
    // this is a workaround to avoid the need to create a new endpoint on the backend
    // we are just storing the response in a different state
    fetchItems({ commit }, data) {
      return new Promise((resolve, reject) => {
        customers
          .get(data)
          .then((response) => {
            commit("setSecondItemsList", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getLinkedUsers({ commit }, data) {
      return new Promise((resolve, reject) => {
        customers
          .getLinkedUsers(data)
          .then((response) => {
            commit("setUsers", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    saveLinkedUsers({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        customers
          .saveLinkedUsers(data)
          .then((response) => {
            dispatch("getLinkedUsers", data.id);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    saveEditedItem({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        customers
          .store(state.editedItem)
          .then((response) => {
            dispatch("fetchAll");
            dispatch("resetEditedItem");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateEditedItem({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        customers
          .update(state.editedId, state.editedItem)
          .then((response) => {
            dispatch("fetchAll");
            dispatch("resetEditedItem");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //eslint-disable-next-line
    deleteItem({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        customers
          .delete(id)
          .then((response) => {
            // dispatch("fetchAll");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    resetEditedItem({ commit, state }) {
      commit("setEditedId", -1);
      commit("setEditedItem", Object.assign({}, state.defaultItem));
    }
  },
  getters: {}
};
