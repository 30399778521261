<template>
  <div id="chart">
    <apexchart
      :class="{ apexChart: isInCarousel }"
      type="donut"
      :options="chartOptions"
      :series="stats.totals"
    ></apexchart>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SecondCarouselItem",
  props: {
    isInCarousel: {
      type: Boolean,
      default: true
    },
    isSplited: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      stats: (state) => state.statsCustomer.statsCustomer
    })
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "donut"
        },
        dataLabels: {
          enabled: true
        },
        labels: ["NON ALLOCATED"],
        colors: [
          "#FFB300",
          "#B66A00",
          "#80BDB8",
          "#0092E2",
          "#22605B",
          "#397D7D",
          "#005C87",
          "#DDD8D9",
          "#858482",
          "#3761C2",
          "#532571",
          "#B6743A"
        ],
        title: {
          text: this.isSplited
            ? ""
            : this.$t("ca-by-sub-franchise", {
                year: new Date().getFullYear()
              })
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                show: false
              }
            }
          }
        ],
        legend: {
          position: "right",
          offsetY: 0
        }
      }
    };
  },
  watch: {
    stats: {
      handler(newStats) {
        this.chartOptions.labels = newStats.names;
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.apexChart {
  padding-left: 10px;
  padding-top: 25px;
}
</style>
