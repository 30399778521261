<template>
  <v-container fluid class="container-view">
    <div>
      <!-- todo add search field  and timeline -->
    </div>
    <v-row>
      <v-col cols="9">
        <v-card elevation="9">
          <v-card-title class="title-background">
            <h2 class="text-h6 font-weight-bold">{{ $t("my-cart") }}</h2>
          </v-card-title>

          <v-card-text>
            <!-- @/assets/product.png -->
            <v-data-table
              :headers="headers"
              :items="cart"
              density="compact"
              hide-default-footer
              item-key="name"
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:item.image="{ item }">
                <v-img
                  v-if="item.image"
                  :src="require(`@/assets/${item.image}`)"
                  width="50"
                  height="50"
                  contain
                ></v-img>
                <v-img
                  v-else
                  :src="require(`@/assets/svg/package.svg`)"
                  width="50"
                  height="50"
                  contain
                ></v-img>
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.total="{ item }">
                {{ parseFloat(item.price * item.quantity).toFixed(2) }}
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.quantity="{ item }">
                <v-text-field
                  v-model="item.quantity"
                  solo
                  dense
                  class="mt-2 mb-2"
                  type="number"
                  hide-details
                ></v-text-field>
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.actions="{ item }">
                <v-icon size="small" @click="deleteItem(item)">
                  {{ mdiDelete }}
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="3">
        <v-card elevation="9">
          <v-card-title class="title-background">
            <h2 class="text-h6 font-weight-bold">{{ $t("command-resume") }}</h2>
          </v-card-title>
          <v-card-text>
            <v-table class="px-2 py-2 command-table">
              <tbody>
                <tr>
                  <td class="background-gray text-h7 command-table">
                    {{ $t("total-article") }} :
                  </td>
                  <td class="text-h7 command-table">
                    {{ this.totalProduct() }} points
                  </td>
                </tr>
                <tr>
                  <td class="background-gray text-h7 command-table">
                    {{ $t("available-points") }} :
                  </td>
                  <td class="text-h7 command-table color-point">
                    {{
                      this.stats.totalPoints == 0 || this.stats.totalPoints < 0
                        ? 0
                        : isDivisibleBy(this.stats.totalPoints, 1000000)
                        ? (this.stats.totalPoints / 1000000).toFixed(2) + "M"
                        : isDivisibleBy(this.stats.totalPoints, 1000)
                        ? (this.stats.totalPoints / 1000).toFixed(2) + "K"
                        : this.stats.totalPoints
                    }}
                    points
                  </td>
                </tr>
                <tr>
                  <td class="background-gray text-h7 command-table">
                    {{ $t("total-amount") }} :
                  </td>
                  <td class="text-h7 command-table">
                    {{ parseFloat(this.totalProduct()) }} points
                  </td>
                </tr>
              </tbody>
            </v-table>
            <v-select
              label="Adresse de livraison"
              v-model="deliveryAddress"
              :items="addresses"
              item-title="fullAddress"
              item-value="id"
              variant="outlined"
            ></v-select>

            <v-select
              label="Adresse de facturation"
              v-model="billingAddress"
              :items="addresses"
              item-title="fullAddress"
              item-value="id"
              variant="outlined"
            ></v-select>
            <v-btn
              class="mt-5 color-button text-white"
              variant="elevated"
              block
              @click="checkout()"
            >
              {{ $t("proceed-to-checkout") }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiDelete } from "@mdi/js";
import { mapState } from "vuex";

export default {
  name: "CartView",
  data() {
    return {
      mdiDelete,
      delivery: 100,
      billingAddress: "",
      deliveryAddress: "",
      headers: [
        { title: "Produit", align: "start", sortable: false, key: "image" },
        {
          title: "Référence",
          align: "start",
          sortable: true,
          key: "product.reference"
        },
        {
          title: "Description",
          align: "start",
          sortable: true,
          key: "product.description"
        },
        {
          title: "Point",
          align: "start",
          sortable: true,
          width: "15vh",
          key: "price"
        },
        {
          title: "Quantité",
          align: "start",
          sortable: true,
          width: "15vh",
          key: "quantity"
        },
        {
          title: "Total",
          align: "start",
          sortable: true,
          width: "15vh",
          key: "total"
        },
        { title: "Actions", align: "end", sortable: true, key: "actions" }
      ]
    };
  },
  computed: {
    ...mapState({
      cart: (state) => state.cart.items.cartItems,
      stats: (state) => state.statsCustomer.statsCustomer,
      addresses: (state) => state.addresses.items
    })
  },
  methods: {
    checkout() {
      if (confirm("Voulez-vous valider votre commande ?")) {
        if (this.deliveryAddress == "" || this.billingAddress == "") {
          this.$store.dispatch("showSnackbar", {
            message:
              "Veuillez choisir une adresse de livraison et de facturation",
            color: "error"
          });
        } else {
          this.$store
            .dispatch("ordersOneStryker/createOrder", {
              customerId: this.$store.state.users.selectedCustomer.id,
              fK_ShippingAddressId: this.deliveryAddress,
              fK_BillingAddressId: this.billingAddress
            })
            .then(() => {
              this.$store.dispatch("showSnackbar", {
                message: "Commande validée",
                color: "success"
              });
              // this.$router.push({ name: "home" });
            })
            .catch(() => {
              this.$store.dispatch("showSnackbar", {
                message: "Erreur lors de la validation de la commande",
                color: "error"
              });
            });
        }
      }
    },
    deleteItem(item) {
      if (confirm(this.$t("alert.delete.cart-item-remove-confirm"))) {
        this.$store
          .dispatch("cart/deleteItem", item.cartItemId)
          .then(() => {
            const index = this.cart.findIndex(
              (cartItem) => cartItem.cartItemId === item.cartItemId
            );
            if (index !== -1) {
              this.cart.splice(index, 1);
            }
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.success.cart-item-removed"),
              color: "success"
            });
          })
          .catch(() => {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.error.cart-item-removed"),
              color: "error"
            });
          });
      }
    },
    isDivisibleBy(value, divisor) {
      return Math.floor(value / divisor) > 0;
    },
    totalProduct() {
      var totalP = 0;
      this.cart.forEach((item) => {
        totalP += item.price * item.quantity;
      });
      return totalP.toFixed(2);
    }
  },
  created() {}
};
</script>

<style>
.command-table {
  border-bottom: none !important;
}
</style>
