<template>
  <v-container class="container-view" fluid>
    <v-row class="welcome-container d-flex align-center">
      <v-col cols="7" class="ml-3 welcome-title-col">
        <h1 class="welcome-client">{{ $t("welcome-on-your-space") }}</h1>
      </v-col>
      <v-col cols="5" class="">
        <div class="pl-5 text-h6 font-weight-black">
          {{ this.customer.name }}
        </div>
        <div class="pl-5">
          <span class="text-h6 font-weight-black">
            Status
            {{
              this.customer.status.number == "" ||
              this.customer.status.number < 0 ||
              this.customer.status.number == null
                ? "0"
                : this.customer.status.number
            }}
            -
          </span>
          <span class="color-point">
            {{
              this.stats.totalPoints == 0 || this.stats.totalPoints < 0
                ? 0
                : isDivisibleBy(this.stats.totalPoints, 1000000)
                ? (this.stats.totalPoints / 1000000).toFixed(2) + "M"
                : isDivisibleBy(this.stats.totalPoints, 1000)
                ? (this.stats.totalPoints / 1000).toFixed(2) + "K"
                : this.stats.totalPoints
            }}
            points restants
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row class="pb-8 pt-0">
      <v-col cols="8">
        <v-card
          class="mx-auto text-center pl-0 pr-0"
          color="white"
          max-width="99%"
          dark
          elevation="6"
        >
          <v-card-title class="title-background text-left">
            {{ $t("some-data") }}
          </v-card-title>
          <v-card-text class="pl-0 pr-0 pb-0">
            <first-carousel-item></first-carousel-item>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card
          class="mx-auto text-center pl-0 pr-0"
          color="white"
          max-width="99%"
          dark
          elevation="6"
        >
          <v-card-title class="title-background text-left">
            {{ $t("ca-by-sub-franchise", { year: new Date().getFullYear() }) }}
          </v-card-title>
          <v-card-text class="pl-0 pr-0 pb-0">
            <second-carousel-item :isSplited="true"></second-carousel-item>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="pub-container pb-8" no-gutters>
      <v-col cols="12">
        <v-img src="@/assets/svg/PUB.svg" class="pub-container" cover> </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import FirstCarouselItem from "@/components/FirstCarouselItem.vue";
import SecondCarouselItem from "@/components/SecondCarouselItem.vue";
import { mapState } from "vuex";
import {
  mdiStarOutline,
  mdiPackageVariant,
  mdiAccountBoxOutline,
  mdiCogOutline,
  mdiForumOutline
} from "@mdi/js";

export default defineComponent({
  name: "HomeView",
  components: {
    FirstCarouselItem,
    SecondCarouselItem
  },
  data() {
    return {
      mdiStarOutline,
      mdiPackageVariant,
      mdiAccountBoxOutline,
      mdiCogOutline,
      mdiForumOutline,
      value: [423, 446, 675, 510, 590, 610, 760, 400, 850, 900, 1200, 548, 154]
    };
  },
  methods: {
    isDivisibleBy(value, divisor) {
      return Math.floor(value / divisor) > 0;
    },
    pushView(view) {
      if (this.$route.name != view) {
        this.$router.push({ name: view });
      }
    }
  },
  computed: {
    ...mapState({
      customer: (state) => state.users.selectedCustomer,
      stats: (state) => state.statsCustomer.statsCustomer
    })
  }
});
</script>

<style>
.pub-container {
  max-height: 275px;
}

.welcome-container {
  max-height: 275px;
  margin-bottom: 20px;
}

.welcome-title-col {
  flex: 0 0 56% !important;
  color: white;
  background: -webkit-linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour les anciennes versions de Chrome et Safari */
  background: -moz-linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour Firefox */
  background: -o-linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour les anciennes versions d'Opera */
  background: linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour les navigateurs modernes */
}

.color-point {
  color: #ffb500;
}

.container-view {
  margin-top: 30px;
}

.title-background {
  background-color: #535756;
  color: white;
}
</style>
