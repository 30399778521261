<template>
  <v-container fluid>
    <v-data-table-server
      :headers="headers"
      :items="gimSubBrands"
      :search="search"
      density="compact"
      :items-length="totalItems"
      :loading="loadingDatatables"
      @update:sortBy="toSort"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            density="compact"
            :label="this.$t('search')"
            class="pl-2"
            :prepend-inner-icon="mdiMagnify"
            @update:modelValue="toSearchBack"
            variant="solo-filled"
            flat
            hide-details
            single-line
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            class="mb-2 text-white mr-3"
            :prepend-icon="mdiAccountPlus"
            color="#ffb300"
            @click="openDialog"
            dark
          >
            {{ $t("admin.new-gim-sub-brand") }}
          </v-btn>
        </v-toolbar>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon class="me-2" size="small" @click="editItem(item)">
          {{ mdiPencil }}
        </v-icon>
        <v-icon size="small" @click="deleteItem(item)">
          {{ mdiDelete }}
        </v-icon>
      </template>

      <template v-slot:bottom>
        <pagination-dynamic :store="'gimSubBrands'"></pagination-dynamic>
      </template>
    </v-data-table-server>
    <add-edit-gim-sub-brand
      :modelDialog="gimSubBrandDialog"
      @close="gimSubBrandDialog = false"
    ></add-edit-gim-sub-brand>
  </v-container>
</template>

<script>
import { mdiPencil, mdiDelete, mdiMagnify, mdiAccountPlus } from "@mdi/js";
import PaginationDynamic from "@/components/PaginationDynamic.vue";
import AddEditGimSubBrand from "./admin/AddEditGimSubBrand.vue";
import paginate from "@/mixins/paginations";
import { mapState } from "vuex";

export default {
  name: "GimProductLineAdmin",
  components: { AddEditGimSubBrand, PaginationDynamic },
  mixins: [paginate],
  data() {
    return {
      mdiAccountPlus,
      mdiMagnify,
      mdiPencil,
      mdiDelete,
      dialog: false,
      dialogDelete: false,
      gimSubBrandDialog: false,
      itemsPerPage: 15,
      page: 1,
      headers: [
        { title: "Nom", key: "name" },
        { title: "Actions", key: "actions", sortable: false, align: "end" }
      ]
    };
  },
  computed: {
    ...mapState({
      gimSubBrands: (state) => state.gimSubBrands.items,
      loadingDatatables: (state) => state.loadingTables,
      totalItems: (state) => state.paginations.totalCount,
      editedId: (state) => state.gimSubBrands.editedId
    }),
    pageCount() {
      return Math.ceil(this.gimSubBrands.length / this.itemsPerPage);
    },
    search: {
      get() {
        return this.$store.state.paginations.search;
      },
      set(value) {
        this.$store.commit("paginations/setSearch", value);
      }
    }
  },
  methods: {
    toSort(item) {
      this.sortBy(item, "gimSubBrands");
    },
    toSearchBack(value, time = 500) {
      this.searchBack(value, time, "gimSubBrands");
    },
    openDialog() {
      this.$store.commit("gimSubBrands/setEditedId", -1);
      this.$store.commit("gimSubBrands/setEditedItem", Object.assign({}, {}));
      this.gimSubBrandDialog = true;
    },
    editItem(item) {
      this.$store.commit("gimSubBrands/setEditedId", item.id);
      this.$store.commit("gimSubBrands/setEditedItem", Object.assign({}, item));
      this.gimSubBrandDialog = true;
    },
    deleteItem(item) {
      if (confirm(this.$t("alert.delete.gim-sub-brand"))) {
        this.$store
          .dispatch("gimSubBrands/deleteItem", item.id)
          .then(() => {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.success.gim-sub-brand-delete"),
              color: "success"
            });
            this.toSearchBack(this.search, 500, "gimSubBrands");
          })
          .catch(() => {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.error.gim-sub-brand-delete"),
              color: "error"
            });
          });
      }
    }
  }
};
</script>
