import axios from "axios";

export default {
  get(paginationString) {
    if (paginationString) {
      return axios.get(`/api/sapgs${paginationString.text}`);
    } else {
      return axios.get("/api/sapgs");
    }
  },
  store(sapg) {
    return axios.post("/api/sapgs", sapg);
  },
  delete(id) {
    return axios.delete(`/api/sapgs/${id}`);
  },
  update(id, sapg) {
    return axios.put(`/api/sapgs/${id}`, sapg);
  }
};
