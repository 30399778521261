import products from "../../api/products";

export default {
  namespaced: true,
  state: {
    items: [],
    editedId: -1,
    editedItem: {
      reference: "",
      description: "",
      descriptionLocal: "",
      currency: "",
      currentCost: 0,
      franchise: null,
      franchiseGroup: null,
      gimBrand: null,
      gimItemType: null,
      gimProductLine: null,
      gimSubBrand: null,
      SAPG: null,
      subFranchise: null,
      targetProductGroup: null,
      gimID: null,
      gimPackContent: 0,
      gimSterilityFlag: 0,
      gimUOM: "",
      gtinCode: "",
      lppr: "",
      price: 0,
      primaryUOM: "",
      tva: null,
      updatedAt: null
    },
    defaultItem: {
      reference: "",
      description: "",
      descriptionLocal: "",
      currency: "",
      currentCost: 0,
      franchise: null,
      franchiseGroup: null,
      gimBrand: null,
      gimItemType: null,
      gimProductLine: null,
      gimSubBrand: null,
      SAPG: null,
      subFranchise: null,
      targetProductGroup: null,
      gimID: null,
      gimPackContent: 0,
      gimSterilityFlag: 0,
      gimUOM: "",
      gtinCode: "",
      lppr: "",
      price: 0,
      primaryUOM: "",
      tva: null,
      updatedAt: null
    }
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setEditedItem(state, item) {
      const editedItem = {
        reference: item.reference,
        description: item.description,
        descriptionLocal: item.descriptionLocal,
        currency: item.currency,
        currentCost: item.currentCost,
        franchise: item.franchise,
        franchiseGroup: item.franchiseGroup,
        gimBrand: item.gimBrand,
        gimItemType: item.gimItemType,
        gimProductLine: item.gimProductLine,
        gimSubBrand: item.gimSubBrand,
        sapg: item.sapg,
        subFranchise: item.subFranchise,
        targetProductGroup: item.targetProductGroup,
        gimID: item.gimID,
        gimPackContent: item.gimPackContent,
        gimSterilityFlag: item.gimSterilityFlag,
        gimUOM: item.gimUOM,
        gtinCode: item.gtinCode,
        lppr: item.lppr,
        price: item.price,
        primaryUOM: item.primaryUOM,
        tva: item.tva,
        updatedAt: item.updatedAt
      };
      state.editedItem = editedItem;
    },
    setEditedId(state, id) {
      state.editedId = id;
    }
  },
  actions: {
    fetchAll({ commit }, data) {
      return new Promise((resolve, reject) => {
        products
          .get(data)
          .then((response) => {
            commit("setItems", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    saveEditedItem({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        products
          .store(state.editedItem)
          .then((response) => {
            dispatch("fetchAll");
            dispatch("resetEditedItem");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateEditedItem({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        products
          .update(state.editedId, state.editedItem)
          .then((response) => {
            dispatch("fetchAll");
            dispatch("resetEditedItem");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //eslint-disable-next-line
    deleteItem({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        products
          .delete(id)
          .then((response) => {
            // dispatch("fetchAll");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    resetEditedItem({ commit, state }) {
      commit("setEditedId", -1);
      commit("setEditedItem", Object.assign({}, state.defaultItem));
    }
  },
  getters: {}
};
