<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card :prepend-icon="mdiAccount" :title="formTitle">
      <v-form ref="form" @submit.prevent>
        <v-card-text>
          <v-row class="d-flex flex-row">
            <v-col cols="12">
              <v-skeleton-loader
                v-if="loadingSkeleton"
                :loading="loadingSkeleton"
                class="mx-auto"
                elevation="2"
                max-width="560"
                width="540"
                type="card-avatar, article, actions"
                boilerplate
              ></v-skeleton-loader>

              <v-autocomplete
                v-else
                v-model="usersLinked"
                class="autocomplete-customer-user"
                clearable
                chips
                multiple
                label="Utilisateurs ayant accès à ce client"
                :items="users"
                @update:search="debouncedUpdateUsers"
                item-title="firstName"
                item-value="id"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            :text="$t('close')"
            variant="plain"
            @click="$emit('close')"
          ></v-btn>

          <v-btn
            color="#ffb500"
            type="submit"
            :text="$t('save')"
            variant="tonal"
            @click="saveLinkedUsers"
          ></v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import paginations from "@/mixins/paginations";
import _ from "lodash"; // Importer lodash
import { mdiAccount } from "@mdi/js";

export default {
  name: "UserCustomerDialog",
  props: ["modelDialog"],
  mixins: [paginations],
  data() {
    return {
      mdiAccount,
      tab: "general",
      loadingSkeleton: false,
      formTitle: this.$t("admin.customer-user")
    };
  },
  created() {
    // Déclarer une méthode débouncée
    this.debouncedUpdateUsers = _.debounce(this.updateUsers, 300);
  },
  computed: {
    ...mapState({
      editedId: (state) => state.clients.editedId,
      users: (state) => state.users.items
    }),
    usersLinked: {
      get() {
        return this.$store.state.clients.users;
      },
      set(value) {
        this.$store.commit("clients/setUsers", value);
      }
    },
    dialog: {
      get() {
        return this.modelDialog;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    updateUsers(item) {
      this.searchBack(item, 500, "users", false);
    },
    saveLinkedUsers() {
      const valid = this.usersLinked.length > 0;
      if (valid) {
        const data = {
          users: this.usersLinked,
          id: this.editedId
        };
        this.$store
          .dispatch("clients/saveLinkedUsers", data)
          .then(() => {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.success.customer-user"),
              color: "success"
            });
          })
          .catch(() => {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.error.customer-user"),
              color: "error"
            });
          });
      } else {
        this.$store.dispatch("showSnackbar", {
          message: this.$t("alert.error.customer-user-rules"),
          color: "error"
        });
        this.close();
      }
    }
  }
};
</script>
