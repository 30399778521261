import statsCustomer from "../../api/statsCustomer";

export default {
  namespaced: true,
  state: {
    items: [],
    statsCustomer: {},
    statsForAdmin: {}
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setStatsCustomer(state, statsCustomer) {
      state.statsCustomer = statsCustomer;
    },
    setStatsForAdmin(state, statsForAdmin) {
      state.statsForAdmin = statsForAdmin;
    }
  },
  actions: {
    recalculatePoints({ commit }, data) {
      return new Promise((resolve, reject) => {
        statsCustomer
          .recalculatePoints(data)
          .then((response) => {
            if (response.data != "" && response.data.statsCustomer) {
              var dataStats = response.data.statsCustomer;
              dataStats.names = response.data.subFranchises.names || [];
              dataStats.totals = response.data.subFranchises.totals || [];
              commit("setStatsCustomer", dataStats);
            } else {
              // Set default values when statsCustomer is null
              const statsNull = {
                capitalPoints: 0,
                implantPoints: 0,
                servicePoints: 0,
                manualPoints: 0,
                consommablePoints: 0,
                totalPoints: 0,
                totalPastYear: 0,
                statusPastYear: -1,
                totalCurrentYear: 0,
                names: [],
                totals: []
              };
              // Handle the case where statsCustomer is null
              commit("setStatsCustomer", statsNull);
            }
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchAll({ commit }, data) {
      return new Promise((resolve, reject) => {
        statsCustomer
          .get(data)
          .then((response) => {
            commit("setItems", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetChSpecificStatsCustomer({ commit }, dataCustomer) {
      return new Promise((resolve, reject) => {
        statsCustomer
          .getByCustomerId(dataCustomer.id)
          .then((response) => {
            if (response.data != "" && response.data.statsCustomer) {
              var dataStats = response.data.statsCustomer;
              dataStats.names = response.data.subFranchises.names || [];
              dataStats.totals = response.data.subFranchises.totals || [];
              dataStats.cost = response.data.deliveryCostAndBillingFees || [];
              commit("setStatsCustomer", dataStats);
              if (dataCustomer.name != "admin") {
                commit("users/setSelectedCustomer", response.data.customer, {
                  root: true
                });
              }
            } else {
              // Set default values when statsCustomer is null
              const statsNull = {
                capitalPoints: 0,
                implantPoints: 0,
                servicePoints: 0,
                manualPoints: 0,
                consommablePoints: 0,
                totalPoints: 0,
                totalPastYear: 0,
                statusPastYear: -1,
                totalCurrentYear: 0,
                names: [],
                totals: []
              };
              // Handle the case where statsCustomer is null
              commit("setStatsCustomer", statsNull);
              if (dataCustomer.name != "admin") {
                commit("users/setSelectedCustomer", response.data.customer, {
                  root: true
                });
              }
            }
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    }
  },
  getters: {}
};
