import axios from "axios";

export default {
  get(paginationString) {
    if (paginationString) {
      return axios.get(`/api/gimSubBrands${paginationString.text}`);
    } else {
      return axios.get("/api/gimSubBrands");
    }
  },
  store(gimSubBrand) {
    return axios.post("/api/gimSubBrands", gimSubBrand);
  },
  delete(id) {
    return axios.delete(`/api/gimSubBrands/${id}`);
  },
  update(id, gimSubBrand) {
    return axios.put(`/api/gimSubBrands/${id}`, gimSubBrand);
  }
};
