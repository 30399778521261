<template>
  <v-container class="container-view" fluid>
    <v-row class="d-flex align-center justify-center flex-wrap text-center">
      <div class="text-h5 font-weight-medium">{{ this.customer.name }}</div>
    </v-row>
    <v-row class="d-flex align-center justify-center flex-wrap text-center">
      <div class="text-h6 font-weight-medium">
        <span class="text-h6 font-weight-black">
          Status
          {{
            this.customer.status.number == "" ||
            this.customer.status.number < 0 ||
            this.customer.status.number == null
              ? "0"
              : this.customer.status.number
          }}
          -
        </span>
        <span class="color-point">
          {{
            this.stats.totalPoints == 0 || this.stats.totalPoints < 0
              ? 0
              : isDivisibleBy(this.stats.totalPoints, 1000000)
              ? (this.stats.totalPoints / 1000000).toFixed(2) + "M"
              : isDivisibleBy(this.stats.totalPoints, 1000)
              ? (this.stats.totalPoints / 1000).toFixed(2) + "K"
              : this.stats.totalPoints
          }}
          points restants
        </span>
      </div>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-card elevation="6">
          <v-tabs
            v-model="tab"
            align-tabs="start"
            color="#FFB300"
            slider-color="white"
            bg-color="#535756"
          >
            <v-tab elevation="6" value="commands">
              {{ $t("my-last-commands") }}
            </v-tab>
            <v-tab elevation="6" value="cost">{{ $t("my-cost") }}</v-tab>
          </v-tabs>

          <v-tabs-window v-model="tab">
            <v-tabs-window-item value="commands">
              <v-card>
                <v-card-text>
                  <v-data-table
                    v-model:page="page"
                    show-expand
                    :items="commandes"
                    :headers="headers"
                    :items-per-page="itemsPerPage"
                    :no-data-text="$t('no-data')"
                    item-value="orderNumber"
                    v-model:expanded="expanded"
                  >
                    <!-- eslint-disable-next-line -->
                    <template v-slot:expanded-row="{ columns, item }">
                      <tr>
                        <td :colspan="columns.length">
                          <v-sheet elevation="9">
                            <v-data-table
                              show-expand
                              :items="item.orders"
                              :headers="headersOrders"
                              item-value="invoiceNumber"
                              density="compact"
                              hide-default-footer
                              hide-default-header
                            >
                              <!-- eslint-disable-next-line -->
                              <template
                                v-slot:item="{
                                  item,
                                  columns,
                                  internalItem,
                                  toggleExpand,
                                  isExpanded
                                }"
                              >
                                <tr>
                                  <!-- eslint-disable-next-line -->
                                  <td v-for="column in columns">
                                    <v-btn
                                      v-if="column.key === 'data-table-expand'"
                                      :icon="
                                        isExpanded(internalItem)
                                          ? mdiArrowUpDropCircleOutline
                                          : mdiArrowDownDropCircleOutline
                                      "
                                      variant="plain"
                                      @click="toggleExpand(internalItem)"
                                    ></v-btn>
                                    <span v-else>{{
                                      $t("invoice-number") +
                                      " : " +
                                      item[column.key]
                                    }}</span>
                                  </td>
                                </tr>
                              </template>
                              <template v-slot:expanded-row="{ columns, item }">
                                <tr>
                                  <td :colspan="columns.length">
                                    <v-sheet elevation="9" class="mt-3 mb-3">
                                      <v-table>
                                        <thead>
                                          <tr class="grey lighten-4">
                                            <th>{{ $t("reference") }}</th>
                                            <th>{{ $t("description") }}</th>
                                            <th>{{ $t("price") }}</th>
                                            <th>{{ $t("quantity") }}</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            v-for="(
                                              product, index
                                            ) in item.products"
                                            :key="index"
                                            class="grey lighten-4"
                                          >
                                            <td>
                                              {{ product.reference }}
                                            </td>
                                            <td>
                                              {{ product.description }}
                                            </td>
                                            <td>
                                              {{ product.salesAmount }}
                                            </td>
                                            <td>
                                              {{ product.quantity }}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </v-table>
                                    </v-sheet>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                          </v-sheet>
                        </td>
                      </tr>
                    </template>
                    <template v-slot:bottom>
                      <div class="text-center pt-2">
                        <v-pagination
                          v-model="page"
                          :length="pageCount"
                        ></v-pagination>
                      </div>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tabs-window-item>

            <v-tabs-window-item value="cost">
              <v-card>
                <v-card-text>
                  <v-data-table
                    :items="stats.cost"
                    :headers="headersCost"
                    hide-default-footer
                    :no-data-text="$t('no-data')"
                  >
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.totalBillingFees="{ item }">
                      {{ item.totalBillingFees }} €
                    </template>

                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.totalDeliveryCost="{ item }">
                      {{ item.totalDeliveryCost }} €
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tabs-window-item>
          </v-tabs-window>
        </v-card>
      </v-col>
      <v-col cols="4" class="pl-0">
        <div id="chart">
          <apexchart
            class="apex-chart"
            type="donut"
            width="500"
            :options="chartOptions"
            :series="stats.totals"
          ></apexchart>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import {
  mdiArrowUpDropCircleOutline,
  mdiArrowDownDropCircleOutline
} from "@mdi/js";

export default {
  name: "StrykerCommands",
  data() {
    return {
      tab: null,
      mdiArrowDownDropCircleOutline,
      mdiArrowUpDropCircleOutline,
      page: 1,
      expanded: [],
      itemsPerPage: 10,
      headers: [
        {
          title: this.$t("command-date"),
          align: "start",
          sortable: false,
          key: "date"
        },
        {
          title: this.$t("order-number"),
          align: "start",
          key: "orderNumber"
        },
        { title: this.$t("total"), align: "start", key: "total" }
      ],
      headersCost: [
        {
          title: this.$t("year"),
          align: "start",
          sortable: false,
          key: "year"
        },
        {
          title: this.$t("total-billing-fees"),
          align: "start",
          key: "totalBillingFees"
        },
        {
          title: this.$t("total-delivery-cost"),
          align: "start",
          key: "totalDeliveryCost"
        }
      ],
      headersOrders: [
        {
          title: this.$t("invoice-number"),
          align: "start",
          key: "invoiceNumber"
        }
      ],
      chartOptions: {
        chart: {
          width: 380,
          type: "donut"
        },
        dataLabels: {
          enabled: true
        },
        labels: ["NON ALLOCATED"],
        colors: [
          "#FFB300",
          "#B66A00",
          "#80BDB8",
          "#0092E2",
          "#22605B",
          "#397D7D",
          "#005C87",
          "#DDD8D9",
          "#858482",
          "#3761C2",
          "#532571",
          "#B6743A"
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                show: false
              }
            }
          }
        ],
        legend: {
          position: "right",
          offsetY: 0
        }
      }
    };
  },
  watch: {
    stats: {
      handler(newStats) {
        this.chartOptions.labels = newStats.names;
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      customer: (state) => state.users.selectedCustomer,
      stats: (state) => state.statsCustomer.statsCustomer
    }),
    pageCount() {
      return Math.ceil(this.commandes.length / this.itemsPerPage);
    },
    commandes: {
      get() {
        return this.$store.state.strykerCommands.items;
      }
    }
  },
  methods: {
    isDivisibleBy(value, divisor) {
      return Math.floor(value / divisor) > 0;
    }
  }
};
</script>

<style scoped>
.apex-chart {
  padding-top: 50px;
}
</style>
