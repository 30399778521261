<template>
  <v-container fluid class="container-view">
    <v-card>
      <v-tabs
        v-model="tab"
        @update:modelValue="tabUpdated"
        align-tabs="start"
        color="#FFB300"
        slider-color="white"
      >
        <v-tab elevation="6" value="clients">{{ $t("admin.customers") }}</v-tab>
        <v-tab elevation="6" value="users">{{ $t("admin.users") }}</v-tab>
        <v-tab elevation="6" value="products">{{ $t("products") }}</v-tab>
        <v-tab elevation="6" value="addresses">{{ $t("addresses") }}</v-tab>
        <v-tab elevation="6" value="strykerCommands"
          >{{ $t("stryker-commands") }}
        </v-tab>
        <v-tab elevation="6" value="ordersOneStryker"
          >{{ $t("orders-stryker-one") }}
        </v-tab>
        <v-tab elevation="6" value="buyingGroups">
          {{ $t("buying-group") }}
        </v-tab>
        <v-tab elevation="6" value="franchises">
          {{ $t("admin.franchise") }}
        </v-tab>
        <v-tab elevation="6" value="franchiseGroups">
          {{ $t("admin.franchise-group") }}
        </v-tab>
        <v-tab elevation="6" value="subFranchises">
          {{ $t("admin.sub-franchise") }}
        </v-tab>
        <v-tab elevation="6" value="sapgs">
          {{ $t("admin.sapg") }}
        </v-tab>
        <v-tab elevation="6" value="targetProductGroups">
          {{ $t("admin.target-product-group") }}
        </v-tab>
        <v-tab elevation="6" value="gimBrands">
          {{ $t("admin.gim-brand") }}
        </v-tab>
        <v-tab elevation="6" value="gimItemTypes">
          {{ $t("admin.gim-item-type") }}
        </v-tab>
        <v-tab elevation="6" value="gimProductLines">
          {{ $t("admin.gim-product-line") }}
        </v-tab>
        <v-tab elevation="6" value="gimSubBrands">
          {{ $t("admin.gim-sub-brand") }}
        </v-tab>
        <v-tab elevation="6" value="gimCustoms">
          {{ $t("admin.gim-custom") }}
        </v-tab>
        <v-tab elevation="6" value="sapgCustoms">
          {{ $t("admin.sapg-custom") }}
        </v-tab>
      </v-tabs>

      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="clients">
          <client-admin></client-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="users">
          <user-admin></user-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="products">
          <product-admin></product-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="addresses">
          <address-admin></address-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="strykerCommands">
          <stryker-command-admin></stryker-command-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="ordersOneStryker">
          <order-one-stryker-admin></order-one-stryker-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="buyingGroups">
          <buying-group-admin></buying-group-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="franchises">
          <franchise-admin></franchise-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="franchiseGroups">
          <franchise-group-admin></franchise-group-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="subFranchises">
          <sub-franchise-admin></sub-franchise-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="sapgs">
          <sapg-admin></sapg-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="targetProductGroups">
          <target-product-group-admin></target-product-group-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="gimBrands">
          <gim-brand-admin></gim-brand-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="gimItemTypes">
          <gim-item-type-admin></gim-item-type-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="gimProductLines">
          <gim-product-line-admin></gim-product-line-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="gimSubBrands">
          <gim-sub-brand-admin></gim-sub-brand-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="gimCustoms">
          <gim-custom-admin></gim-custom-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="sapgCustoms">
          <sapg-custom-admin></sapg-custom-admin>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card>
  </v-container>
</template>

<script>
import ClientAdmin from "@/components/ClientAdmin.vue";
import UserAdmin from "@/components/UserAdmin.vue";
import ProductAdmin from "@/components/ProductAdmin.vue";
import StrykerCommandAdmin from "@/components/StrykerCommandAdmin.vue";
import BuyingGroupAdmin from "@/components/BuyingGroupAdmin.vue";
import FranchiseAdmin from "@/components/FranchiseAdmin.vue";
import FranchiseGroupAdmin from "@/components/FranchiseGroupAdmin.vue";
import SubFranchiseAdmin from "@/components/SubFranchiseAdmin.vue";
import TargetProductGroupAdmin from "@/components/TargetProductGroupAdmin.vue";
import GimBrandAdmin from "@/components/GimBrandAdmin.vue";
import GimItemTypeAdmin from "@/components/GimItemTypeAdmin.vue";
import GimProductLineAdmin from "@/components/GimProductLineAdmin.vue";
import GimSubBrandAdmin from "@/components/GimSubBrandAdmin.vue";
import SapgCustomAdmin from "@/components/SapgCustomAdmin.vue";
import GimCustomAdmin from "@/components/GimCustomAdmin.vue";
import SapgAdmin from "@/components/SapgAdmin.vue";
import AddressAdmin from "@/components/AddressAdmin.vue";
import OrderOneStrykerAdmin from "@/components/OrderOneStrykerAdmin.vue";

export default {
  name: "AdminView",
  components: {
    ClientAdmin,
    UserAdmin,
    ProductAdmin,
    StrykerCommandAdmin,
    BuyingGroupAdmin,
    FranchiseAdmin,
    FranchiseGroupAdmin,
    SubFranchiseAdmin,
    SapgAdmin,
    TargetProductGroupAdmin,
    GimBrandAdmin,
    GimItemTypeAdmin,
    GimProductLineAdmin,
    GimSubBrandAdmin,
    GimCustomAdmin,
    SapgCustomAdmin,
    AddressAdmin,
    OrderOneStrykerAdmin
  },
  data() {
    return {
      tab: null
    };
  },
  methods: {
    tabUpdated(value) {
      this.$store.dispatch("paginations/resetItems");
      this.$store.commit("setOverlay", true);
      if (value == "strykerCommands") {
        this.$store.commit("strykerCommands/setItems", []);
      }
      if (value == "ordersOneStryker") {
        this.$store.commit("ordersOneStryker/setItems", []);
      }
      this.$store.dispatch(`${value}/fetchAll`).then((response) => {
        if (value == "clients") {
          this.$store.dispatch("buyingGroups/fetchAll").then(() => {
            this.$store.commit("setOverlay", false);
            this.$store.commit(
              "paginations/setItems",
              JSON.parse(response.headers.get("X-Pagination"))
            );
          });
        } else if (value == "users") {
          this.$store.dispatch("fetchUserAdmin").then(() => {
            this.$store.commit("setOverlay", false);
            /* this.$store.commit(
              "paginations/setItems",
              JSON.parse(response.headers.get("X-Pagination"))
            ); */
          });
        } else if (value == "products") {
          this.$store.dispatch("fetchProductAdmin").then(() => {
            this.$store.commit("setOverlay", false);
            this.$store.commit(
              "paginations/setItems",
              JSON.parse(response.headers.get("X-Pagination"))
            );
          });
        } else if (value == "addresses") {
          this.$store.dispatch("clients/fetchAll").then(() => {
            this.$store.commit("setOverlay", false);
          });
        } else {
          this.$store.commit("setOverlay", false);
          if (value != "ordersOneStryker") {
            this.$store.commit(
              "paginations/setItems",
              JSON.parse(response.headers.get("X-Pagination"))
            );
          }
        }
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("paginations/setSearch", "");
    next();
  }
};
</script>

<style scoped>
.v-btn.v-theme--light.v-btn--density-default.v-btn--size-default.v-btn--variant-text.v-tab:not(
    .v-tab-item--selected
  ):not(.v-tab--selected) {
  background-color: #ffb300;
  color: white;
}
</style>
