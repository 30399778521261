<template>
  <v-app-bar color="#ffffff" class="app-bar-custom">
    <v-img
      v-if="
        $store.state.auth.token == null ||
        $store.state.users.selectedCustomer.id == -1
      "
      class="ml-5"
      src="../assets/stryker.png"
      @click="pushViews('home')"
      max-width="150"
    ></v-img>
    <v-spacer v-if="$store.state.auth.token == null"></v-spacer>
    <!-- <div class="custom-menu mr-5" v-if="$store.state.auth.token == null">
      <span>CONTACT |</span>
      <span>
        <v-icon :icon="mdiWeb" size="large"></v-icon>FRANCE/FRANÇAIS
      </span>
    </div> -->

    <template
      v-slot:prepend
      v-if="
        $store.state.auth.token !== null &&
        $store.state.users.selectedCustomer.id != -1
      "
    >
      <v-app-bar-nav-icon @click.stop="$emit('update')"></v-app-bar-nav-icon>
    </template>

    <v-spacer v-if="$store.state.auth.token != null"></v-spacer>
    <v-img
      v-if="
        $store.state.auth.token != null &&
        $store.state.users.selectedCustomer.id != -1
      "
      class="ml-5 image-app-bar"
      src="../assets/stryker.png"
      @click="pushViews('home')"
      max-width="150"
    ></v-img>
    <v-spacer v-if="$store.state.auth.token != null"></v-spacer>

    <div
      v-if="
        $store.state.auth.token != null &&
        $store.state.users.selectedCustomer.id != -1
      "
    >
      <v-btn
        v-if="customers.length > 1"
        :icon="mdiSwapHorizontal"
        @click="swapCustomer"
      ></v-btn>
      <v-btn :icon="mdiMagnify"></v-btn>
      <v-btn :icon="mdiAccountOutline"></v-btn>
      <v-btn @click="pushViews('cart')">
        <v-badge color="error" :content="itemCount">
          <v-icon size="x-large">{{ mdiCartMinus }}</v-icon>
        </v-badge>
      </v-btn>
    </div>
    <v-btn
      :icon="mdiLogout"
      @click="logout"
      v-if="$store.state.auth.token != null"
    ></v-btn>
  </v-app-bar>
</template>

<script>
import {
  mdiSwapHorizontal,
  mdiMagnify,
  mdiAccountOutline,
  mdiCartMinus,
  mdiLogout
} from "@mdi/js";
import { mapState } from "vuex";

export default {
  name: "TopBar",
  data() {
    return {
      mdiSwapHorizontal,
      mdiMagnify,
      mdiAccountOutline,
      mdiCartMinus,
      mdiLogout,
      menu: false
    };
  },
  computed: {
    ...mapState({
      customers: (state) => state.users.customers,
      selectedCustomer: (state) => state.users.selectedCustomer,
      itemCount: (state) => state.cart.itemCount
    })
  },
  methods: {
    logout() {
      this.$store.commit("auth/clearAuthData");
      this.$store.dispatch("users/resetSelectedCustomer");
      this.$router.push({ name: "login" });
    },
    pushViews(view) {
      if (this.$route.name != view) {
        if (view == "home" && this.selectedCustomer.id != -1) {
          this.$router.push({ name: view });
        } else if (view == "cart" && this.selectedCustomer.id != -1) {
          this.$store
            .dispatch("cart/fetchCartByCustomer", this.selectedCustomer.id)
            .then(() => {
              this.$store
                .dispatch(
                  "addresses/fetchItemByCustomerId",
                  this.selectedCustomer.id
                )
                .then(() => {
                  this.$router.push({ name: view });
                });
            });
        } else if (view != "home") {
          this.$router.push({ name: view });
        }
      }
    },
    swapCustomer() {
      this.$store.dispatch("users/resetSelectedCustomer");
      localStorage.removeItem("cust_stryker_one");
      this.$router.push({ name: "select-customer" });
    }
  }
};
</script>

<style>
.image-app-bar {
  text-align: center;
}

div.v-toolbar__content {
  align-items: center !important;
}
.app-bar-custom {
  position: relative;
  overflow: visible;
}

.app-bar-custom::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  height: 20px;
  background: -webkit-linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour les anciennes versions de Chrome et Safari */
  background: -moz-linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour Firefox */
  background: -o-linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour les anciennes versions d'Opera */
  background: linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour les navigateurs modernes */
}
</style>
