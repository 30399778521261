import ordersOneStryker from "../../api/ordersOneStryker";

export default {
  namespaced: true,
  state: {
    items: [],
    editedId: -1,
    editedItem: {
      customer: {},
      orderDate: "",
      orderNumber: "",
      status: "",
      totalAmount: ""
    },
    defaultItem: {
      customer: {},
      orderDate: "",
      orderNumber: "",
      status: "",
      totalAmount: ""
    }
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setEditedItem(state, item) {
      const editedItem = {
        customer: item.customer,
        orderDate: item.orderDate,
        orderNumber: item.orderNumber,
        status: item.status,
        totalAmount: item.totalAmount
      };
      state.editedItem = editedItem;
    },
    setEditedId(state, id) {
      state.editedId = id;
    }
  },
  actions: {
    fetchAll({ commit }, data) {
      return new Promise((resolve, reject) => {
        ordersOneStryker
          .get(data)
          .then((response) => {
            commit("setItems", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    createOrder({ commit }, order) {
      return new Promise((resolve, reject) => {
        ordersOneStryker
          .createOrder(order)
          .then((response) => {
            commit("setItems", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchCustommerCommands({ commit }, id) {
      return new Promise((resolve, reject) => {
        ordersOneStryker
          .getCommands(id)
          .then((response) => {
            commit("setItems", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    resetEditedItem({ commit, state }) {
      commit("setEditedId", -1);
      commit("setEditedItem", Object.assign({}, state.defaultItem));
    }
  },
  getters: {}
};
