<template>
  <v-container>
    <v-row class="pt-5">
      <v-col cols="4" :class="{ 'total-points-sheet': isInCarousel }">
        <div v-if="!isInCarousel" class="div-sheet-points">
          <v-sheet
            elevation="8"
            class="d-flex align-center justify-center flex-wrap text-center"
            height="200"
            width="250"
          >
            <div>
              <h2 class="text-h2 font-weight-black text-color">
                {{
                  this.stats.totalPoints == 0 || this.stats.totalPoints < 0
                    ? 0
                    : isDivisibleBy(this.stats.totalPoints, 1000000)
                    ? (this.stats.totalPoints / 1000000).toFixed(2) + "M"
                    : isDivisibleBy(this.stats.totalPoints, 1000)
                    ? (this.stats.totalPoints / 1000).toFixed(2) + "K"
                    : this.stats.totalPoints
                }}
              </h2>

              <div class="text-body-2 mt-2">
                {{
                  this.$t("total-pts-year", {
                    year: new Date().getFullYear()
                  })
                }}
              </div>
            </div>
          </v-sheet>
        </div>
        <div v-else>
          <v-sheet
            elevation="8"
            class="d-flex align-center justify-center flex-wrap text-center"
            height="200"
            width="250"
          >
            <div>
              <h2 class="text-h2 font-weight-black text-color">
                {{
                  this.stats.totalPoints == 0 || this.stats.totalPoints < 0
                    ? 0
                    : isDivisibleBy(this.stats.totalPoints, 1000000)
                    ? (this.stats.totalPoints / 1000000).toFixed(2) + "M"
                    : isDivisibleBy(this.stats.totalPoints, 1000)
                    ? (this.stats.totalPoints / 1000).toFixed(2) + "K"
                    : this.stats.totalPoints
                }}
              </h2>

              <div class="text-body-2 mt-2">
                {{
                  this.$t("total-pts-year", {
                    year: new Date().getFullYear()
                  })
                }}
              </div>
            </div>
          </v-sheet>
        </div>
      </v-col>

      <v-col cols="8">
        <div>
          <v-sheet
            class="first-sheet-carousel-command d-flex align-center"
            elevation="8"
            height="90"
            width="99%"
          >
            <v-row>
              <v-col cols="2" class="pr-0">
                <h2 class="text-h6 font-weight-regular">
                  {{ new Date().getFullYear() - 1 }}
                </h2>
              </v-col>
              <v-divider
                :thickness="2"
                class="border-opacity-100 divider-height"
                vertical
              ></v-divider>
              <v-col>
                <h2 class="text-h5 text-center font-weight-bold">
                  Statut {{ this.stats.statusPastYear }} -
                  {{
                    this.stats.totalPastYear == 0 ||
                    this.stats.totalPastYear < 0
                      ? 0
                      : isDivisibleBy(this.stats.totalPastYear, 1000000)
                      ? (this.stats.totalPastYear / 1000000).toFixed(2) + "M"
                      : isDivisibleBy(this.stats.totalPastYear, 1000)
                      ? (this.stats.totalPastYear / 1000).toFixed(2) + "K"
                      : this.stats.totalPastYear
                  }}€ ventes {{ new Date().getFullYear() - 1 }}
                </h2>
              </v-col>
            </v-row>
          </v-sheet>
        </div>
        <div>
          <v-sheet
            class="second-sheet-carousel-command d-flex align-center"
            elevation="8"
            height="90"
            width="99%"
          >
            <v-row>
              <v-col
                cols="2"
                class="pr-0 d-flex align-center justify-center flex-wrap text-center"
              >
                <h2 class="text-h6 font-weight-regular">
                  {{ new Date().getFullYear() }}
                </h2>
              </v-col>
              <v-divider
                :thickness="2"
                length="60"
                class="border-opacity-100 divider-center"
                vertical
              ></v-divider>
              <v-col>
                <h2 class="text-h6 text-center font-weight-bold">
                  Statut {{ this.customer.status.number }} -
                  {{
                    this.stats.totalCurrentYear == 0 ||
                    this.stats.totalCurrentYear < 0
                      ? 0
                      : isDivisibleBy(this.stats.totalCurrentYear, 1000000)
                      ? (this.stats.totalCurrentYear / 1000000).toFixed(2) + "M"
                      : isDivisibleBy(this.stats.totalCurrentYear, 1000)
                      ? (this.stats.totalCurrentYear / 1000).toFixed(2) + "K"
                      : this.stats.totalCurrentYear
                  }}€ ventes
                  {{ new Date().getFullYear() }}
                </h2>
                <h5 class="text-caption">{{ this.$t("projection") }}</h5>
                <v-row
                  class="d-flex align-center justify-center flex-wrap text-center"
                >
                  <v-col cols="11" class="pt-0 pb-0">
                    <v-slider
                      v-model="statusProgress"
                      :min="0"
                      :max="4"
                      :ticks="tickLabelsStatus"
                      color="#ffb500"
                      show-ticks="always"
                      tick-size="4"
                    >
                      <template v-slot:tick-label="{ tick }">
                        <p class="text-caption font-weight-bold">
                          {{ tick.label }}
                        </p>
                      </template>
                    </v-slider>
                    <!-- <v-slider
                      v-model="statusProgress"
                      :min="400000"
                      :max="2100000"
                      thumb-label="always"
                      :ticks="tickLabels"
                      color="#ffb500"
                      show-ticks="always"
                      tick-size="4"
                    >
                      <template v-slot:thumb-label="{ modelValue }">
                        {{ (modelValue / 1000000).toFixed(2) + "M" }}
                      </template>
                      <template v-slot:tick-label="{ tick }">
                        <p class="text-caption font-weight-bold">
                          {{ tick.label }}
                        </p>
                      </template>
                    </v-slider> -->
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-sheet>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="pl-0 pr-0">
        <v-row
          class="px-0 py-0 mx-0 my-0 d-flex align-center justify-center flex-wrap text-center"
        >
          <v-col cols="11" class="px-0 py-0 mx-0 my-0">
            <div
              class="d-flex align-center justify-center flex-wrap text-center"
            >
              <div>
                <h2 class="text-h4 font-weight-black text-color">
                  {{
                    this.stats.capitalPoints == 0 ||
                    this.stats.capitalPoints < 0
                      ? 0
                      : isDivisibleBy(this.stats.capitalPoints, 1000000)
                      ? (this.stats.capitalPoints / 1000000).toFixed(2) + "M"
                      : isDivisibleBy(this.stats.capitalPoints, 1000)
                      ? (this.stats.capitalPoints / 1000).toFixed(2) + "K"
                      : this.stats.capitalPoints
                  }}
                </h2>

                <div class="text-caption mt-2">
                  {{ this.$t("points") }} {{ new Date().getFullYear() }}
                  {{ this.$t("capital") }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="3" class="pl-0 pr-0">
        <v-row
          class="px-0 py-0 mx-0 my-0 d-flex align-center justify-center flex-wrap text-center"
        >
          <v-divider
            :thickness="1"
            class="border-opacity-50"
            vertical
          ></v-divider>
          <v-col cols="11" class="px-0 py-0 mx-0 my-0">
            <div
              class="d-flex align-center justify-center flex-wrap text-center"
            >
              <div>
                <h2 class="text-h4 font-weight-black text-color">
                  {{
                    this.stats.consommablePoints == 0 ||
                    this.stats.consommablePoints < 0
                      ? 0
                      : isDivisibleBy(this.stats.consommablePoints, 1000000)
                      ? (this.stats.consommablePoints / 1000000).toFixed(2) +
                        "M"
                      : isDivisibleBy(this.stats.consommablePoints, 1000)
                      ? (this.stats.consommablePoints / 1000).toFixed(2) + "K"
                      : this.stats.consommablePoints
                  }}
                </h2>

                <div class="text-caption mt-2">
                  {{ this.$t("points") }} {{ new Date().getFullYear() }}
                  {{ this.$t("consommable") }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="3" class="pl-0 pr-0">
        <v-row
          class="px-0 py-0 mx-0 my-0 d-flex align-center justify-center flex-wrap text-center"
        >
          <v-divider
            :thickness="1"
            class="border-opacity-50"
            vertical
          ></v-divider>
          <v-col cols="11" class="px-0 py-0 mx-0 my-0">
            <div
              class="d-flex align-center justify-center flex-wrap text-center"
            >
              <div>
                <h2 class="text-h4 font-weight-black text-color">
                  {{
                    this.stats.implantPoints == 0 ||
                    this.stats.implantPoints < 0
                      ? 0
                      : isDivisibleBy(this.stats.implantPoints, 1000000)
                      ? (this.stats.implantPoints / 1000000).toFixed(2) + "M"
                      : isDivisibleBy(this.stats.implantPoints, 1000)
                      ? (this.stats.implantPoints / 1000).toFixed(2) + "K"
                      : this.stats.implantPoints
                  }}
                </h2>

                <div class="text-caption mt-2">
                  {{ this.$t("points") }} {{ new Date().getFullYear() }}
                  {{ this.$t("implant") }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="3" class="pl-0 pr-0">
        <v-row
          class="px-0 py-0 mx-0 my-0 d-flex align-center justify-center flex-wrap text-center"
        >
          <v-divider
            :thickness="1"
            class="border-opacity-50"
            vertical
          ></v-divider>
          <v-col cols="11" class="px-0 py-0 mx-0 my-0">
            <div
              class="d-flex align-center justify-center flex-wrap text-center"
            >
              <div>
                <h2 class="text-h4 font-weight-black text-color">
                  {{
                    this.stats.servicePoints == 0 ||
                    this.stats.servicePoints < 0
                      ? 0
                      : isDivisibleBy(this.stats.servicePoints, 1000000)
                      ? (this.stats.servicePoints / 1000000).toFixed(2) + "M"
                      : isDivisibleBy(this.stats.servicePoints, 1000)
                      ? (this.stats.servicePoints / 1000).toFixed(2) + "K"
                      : this.stats.servicePoints
                  }}
                </h2>

                <div class="text-caption mt-2">
                  {{ this.$t("points") }} {{ new Date().getFullYear() }}
                  {{ this.$t("services") }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "FirstCarouselItem",
  props: {
    isInCarousel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      color: "indigo",
      slide: "First",
      tickLabels: {
        0: "Aucun Statut",
        400000: "Statut 1",
        700000: "Statut 2",
        1200000: "Statut 3",
        2100000: "Statut 4"
      },
      tickLabelsStatus: {
        0: "Aucun Statut",
        1: "Statut 1",
        2: "Statut 2",
        3: "Statut 3",
        4: "Statut 4"
      }
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.users.selectedCustomer,
      stats: (state) => state.statsCustomer.statsCustomer
    }),
    statusProgress: {
      get() {
        var value = (
          this.$store.state.statsCustomer.statsCustomer.totalCurrentYear /
          1000000
        ).toFixed(2);
        this.scaleValue(value);
        return this.scaleValue(value);
      }
    }
  },
  methods: {
    scaleValue(value) {
      const min = 0;
      const max = 2.1;
      const newMin = 0;
      const newMax = 4;
      return ((value - min) * (newMax - newMin)) / (max - min) + newMin;
    },
    isDivisibleBy(value, divisor) {
      return Math.floor(value / divisor) > 0;
    }
  }
};
</script>

<style scoped>
.div-sheet-points {
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-text-statut {
  text-align: center;
}

.first-sheet-carousel-command {
  margin-bottom: 20px;
  border-left: 4px solid #feb100;
  border-top-left-radius: 6px; /* Round the top-left corner */
  border-bottom-left-radius: 6px; /* Round the bottom-left corner */
}

.second-sheet-carousel-command {
  margin-top: 20px;
  border-left: 4px solid #feb100;
  border-top-left-radius: 6px; /* Round the top-left corner */
  border-bottom-left-radius: 6px; /* Round the bottom-left corner */
}

.total-points-sheet {
  padding-left: 50px;
}

.text-color {
  color: #feb100;
}

.divider-center {
  align-self: center;
}
</style>
