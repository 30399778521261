import cart from "../../api/cart";

export default {
  namespaced: true,
  state: {
    items: [],
    itemCount: 0,
    editedId: -1,
    editedItem: {
      customerId: "",
      updatedAt: null,
      updatedBy: null
    },
    defaultItem: {
      customerId: "",
      updatedAt: null,
      updatedBy: null
    }
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setEditedItem(state, item) {
      const editedItem = {
        customerId: item.customerId,
        updatedAt: item.updatedAt,
        updatedBy: item.updatedBy
      };
      state.editedItem = editedItem;
    },
    setEditedId(state, id) {
      state.editedId = id;
    },
    setItemCount(state, count) {
      state.itemCount = count;
    }
  },
  actions: {
    fetchCartByCustomer({ commit }, customerId) {
      return new Promise((resolve, reject) => {
        cart
          .getByCustomer(customerId)
          .then((response) => {
            commit("setItems", response.data);
            commit("setEditedId", response.data.cartId);
            commit("setItemCount", response.data.cartItems.length);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    addItemToCart({ commit }, data) {
      return new Promise((resolve, reject) => {
        const productToSend = {
          productId: data.productId,
          quantity: data.quantity,
          price: data.price,
          vat: data.vat,
          customer: data.customer
        };
        cart
          .postItemsCart(productToSend)
          .then((response) => {
            commit("setItemCount", response.data.itemCount);
            commit("setItems", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteItem({ commit }, itemId) {
      return new Promise((resolve, reject) => {
        cart
          .deleteItemsCart(itemId)
          .then((response) => {
            commit("setItemCount", response.data.itemCount);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    resetEditedItem({ commit, state }) {
      commit("setEditedId", -1);
      commit("setEditedItem", Object.assign({}, state.defaultItem));
    }
  },
  getters: {}
};
