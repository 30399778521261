import axios from "axios";

export default {
  get(paginationString) {
    if (paginationString) {
      return axios.get(`/api/subFranchises${paginationString.text}`);
    } else {
      return axios.get("/api/subFranchises");
    }
  },
  store(subFranchise) {
    return axios.post("/api/subFranchises", subFranchise);
  },
  delete(id) {
    return axios.delete(`/api/subFranchises/${id}`);
  },
  update(id, subFranchise) {
    return axios.put(`/api/subFranchises/${id}`, subFranchise);
  }
};
